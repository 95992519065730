import Vuex from 'vuex'
import Vue from 'vue'
import Queue from './vuex'
import SocketService from '../services/Socket.service'
Vue.use(Vuex)

const authToken = JSON.parse(window.localStorage.getItem('authToken'))
const company = JSON.parse(window.localStorage.getItem('company'))
const darkMode = JSON.parse(window.localStorage.getItem('darkMode')) || false

const connectSocket = (company) => {
  if (company && company.cnpj && !SocketService.socket.connected) {
    SocketService.socket.io.opts.query = {
      identification: company.cnpj, name: company.fantasia
    }
    SocketService.socket.connect()
  }
}

const disconnectSocket = () => {
  if (SocketService.socket.connected) {
    SocketService.socket.disconnect()
  }
}

const state = {
  appVersion: process.env.APP_VERSION || '0',
  authToken: authToken,
  company: company,
  darkMode: darkMode
}

connectSocket(company)

const mutations = {
  setAuthToken (state, value) {
    if (value) {
      window.localStorage.setItem('authToken', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('authToken')
    }
    state.authToken = value || null
  },
  setCompany (state, value) {
    if (value) {
      window.localStorage.setItem('company', JSON.stringify(value))
      connectSocket(value)
    } else {
      window.localStorage.removeItem('company')
      disconnectSocket()
    }
    state.company = value
  },
  setDarkMode (state, value) {
    if (value !== undefined && value !== null) {
      window.localStorage.setItem('darkMode', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('darkMode')
    }
    state.darkMode = value
  }
}

const actions = {
  getAppVersion ({ commit }) {
    commit('getAppVersion')
  },
  setAuthToken ({ commit }, value) {
    commit('setAuthToken', value)
  },
  setCompany ({ commit }, value) {
    commit('setCompany', value)
  },
  setDarkMode ({ commit }, value) {
    commit('setDarkMode', value)
  },
  getAuthToken ({ commit }) {
    commit('getAuthToken')
  },
  getCompany ({ commit }) {
    commit('getCompany')
  },
  getDarkMode ({ commit }) {
    commit('getDarkMode')
  },
  ...Queue.actions
}

const getters = {
  getAppVersion () {
    return state.appVersion
  },
  getAuthToken () {
    return state.authToken
  },
  getCompany () {
    return state.company
  },
  getDarkMode () {
    return state.darkMode
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
