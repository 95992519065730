import Vue from 'vue'
import VueRouter from 'vue-router'
import beforeEach from './beforeEach'
// Components routes
import Login from '../views/login/index'
import Register from '../views/register/index'
import Home from '../views/home/index'
import Company from '../views/company/index'
import Chats from '../views/chats/index'
import Error404 from '../views/error404/index'
// Containers
const Full = () => import(/* webpackChunkName: "full" */ '../layouts/Full.vue')
const Clean = () => import(/* webpackChunkName: "clean" */ '../layouts/Clean.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Full',
    component: Full,
    children: [
      Home.index,
      Company.index,
      Chats.index
    ]
  },
  {
    path: '/',
    name: 'Clean',
    component: Clean,
    children: [
      Login.index,
      Register.index,
      Error404.index
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(beforeEach)

export default router
