const Company = () => import(/* webpackChunkName: "company" */ './Index.vue')

const index = {
  path: 'company',
  name: 'Empresa',
  component: Company,
  meta: {
    requiresLogin: true,
    shown: false
  }
}

export default {
  index
}
